import { useState } from "react"
import { ConfirmModal } from "src/components"
import { ActionableType } from "src/types"
import { isValidId } from "src/helpers"
import { DocumentsSection, MenuActionProps, SectionCardProps } from "../../components"

import { useDispatch, useSelector } from "react-redux"
import { applicationEvaluatorFieldsActions } from "src/store/application/actions"

import { filterApprovals } from "../../components/DocumentsFlow/helpers"
import { personalActionableTypes } from "./helpers"
import { DocumentSectionProps } from ".."
import { images } from "src/helpers/images"

export function PersonalDocumentsSection({ applicationId, openDocumentsDialog, setDocumentsDialog }: DocumentSectionProps) {
    const dispatch = useDispatch()

    const organization = useSelector(state => state.organization)
    const customer = useSelector(state => state.auth.customer)
    // const documents = useSelector(state => state.applications.rejectedDocuments?.data) || []
    const documents = useSelector(state => state.applications.switchableEvaluators?.approvals || [])
    const evaluators = useSelector(state => state.applications.switchableEvaluators?.data || [])

    const [destroyConfirmation, setDestroyConfirmation] = useState<{
        open: boolean,
        actionableId?: string,
        actionableType?: ActionableType
    }>({ open: false })

    const personalApprovals = filterApprovals(documents, personalActionableTypes)

    const personalCardsItems: SectionCardProps[] = personalApprovals.length <= 0 ? [] : [
        {
            title: 'Documentos Personales',
            actionTitle: 'Cargar documentos',
            approvals: personalApprovals,
            Icon: images[organization.slug!].personalDocuments,
            hideMenuActions: true,
            handleAction: () => openDocumentsDialog({
                actionableTypes: personalActionableTypes,
                showOnlyDocuments: true
            })
        }
    ]

    const employmentsIds = documents.filter((doc, index, array) =>
        doc?.actionable_type === 'Employment' &&
        array.findIndex(d => d?.actionable_id === doc?.actionable_id) === index
    ).map(doc => doc?.actionable_id || '')

    const bankAccountsIds = documents.filter((doc, index, array) =>
        doc?.actionable_type === 'BankAccount' &&
        array.findIndex(d => d?.actionable_id === doc?.actionable_id) === index
    ).map(doc => doc?.actionable_id || '')

    const employmentsCardsItems: SectionCardProps[] = employmentsIds.map((id, index) => ({
        actionableId: id,
        actionableType: 'Employment',
        title: `Empleo ${index + 1}`,
        actionTitle: 'Cargar documentos',
        approvals: filterApprovals(documents, ['Employment'], id),
        Icon: images[organization.slug!].employmentDocuments,
        handleAction: () => openDocumentsDialog({
            actionableId: id,
            actionableTypes: ['Employment'],
            showOnlyDocuments: true
        })
    }))

    const bankAccountsCardsItems: SectionCardProps[] = bankAccountsIds.map((id, index) => {
        const bankAccount = evaluators.find(doc => doc?.uuid === id)

        return ({
            actionableId: id,
            actionableType: 'BankAccount',
            // @ts-ignore
            title: `Cuenta bancaria - ${bankAccount?.institution_name || index + 1}`,
            actionTitle: 'Cargar documentos',
            approvals: filterApprovals(documents, ['BankAccount'], id),
            Icon: images[organization.slug!].employmentDocuments,
            handleAction: () => openDocumentsDialog({
                actionableId: id,
                actionableTypes: ['BankAccount'],
                showOnlyDocuments: true
            })
        })
    })

    return (
        <>
            <ConfirmModal
                open={destroyConfirmation.open}
                title="Eliminar empleo"
                description="¿Deseas eliminar este empleo?"
                confirmText="Sí, eliminar"
                cancelText="Volver"
                onConfirm={() => {
                    dispatch(applicationEvaluatorFieldsActions.executeEvaluators(
                        'delete',
                        applicationId,
                        destroyConfirmation.actionableType!,
                        { id: destroyConfirmation.actionableId }
                    ))

                    setDestroyConfirmation({ open: false })
                }}
                onCancel={() => setDestroyConfirmation({ open: false })}
            />

            <DocumentsSection
                title={`Acreditado Principal | ${customer?.first_name || ''} ${customer?.last_name || ''}`}
                items={[
                    ...personalCardsItems,
                    ...employmentsCardsItems,
                    ...bankAccountsCardsItems
                ]}
                addButtonProps={{
                    show: false,
                    text: 'Agregar otro empleo',
                    onClick: () => {
                        dispatch(applicationEvaluatorFieldsActions.setActionable({ actionableType: 'Employment' }))
                        setDocumentsDialog({ open: true, types: ['Employment'] })
                    }
                }}
            />
        </>
    )
}